var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"hide-on-scroll":false,"app":"","flat":"","color":_vm.color,"height":"80px","dark":_vm.scrollPosition > 200,"scroll-threshold":"500"}},[_c('v-container',[_c('v-row',{staticClass:"px-4",staticStyle:{"position":"relative"},attrs:{"align":"center"}},[_c('img',{style:({
          opacity: 1 - ( ( 1 * _vm.scrollPosition )  / 200 )
        }),attrs:{"src":_vm.logo,"max-height":"50","height":"50"},on:{"click":function () { return _vm.$router.push(("/entities/" + (_vm.$route.params.entidadId))).catch(function () {}); }}}),_c('v-spacer'),(!_vm.$vuetify.breakpoint.mdAndDown)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"icon":""},on:{"click":function () { return _vm.$router.push(("/entities/" + (_vm.$route.params.entidadId) + "/stores")).catch(function () {}); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-store-outline")])],1)]}}],null,false,1675510366)},[_c('span',[_vm._v(_vm._s(_vm.$t("AppBar.toolbar.stores")))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(_vm.isAuthenticated)?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"icon":""},on:{"click":function () { return _vm.$router.push(("/entities/" + (_vm.$route.params.entidadId) + "/account")).catch(function () {}); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-outline")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("AppBar.toolbar.profile")))])]),(_vm.itemsCount && _vm.isAuthenticated && !_vm.showCart)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$store.commit('switchCartDrawer')}}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"content":_vm.itemsCount,"bordered":""}},[_c('v-icon',{attrs:{"color":""}},[_vm._v("mdi-cart")])],1)],1)]}}],null,false,963841338)},[_c('span',[_vm._v(_vm._s(_vm.$t("AppBar.toolbar.cart"))+" ("+_vm._s(_vm.itemsCount)+")")])]):_vm._e(),(!_vm.$vuetify.breakpoint.mdAndDown)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-web")])],1)]}}],null,false,2337023380)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.langs),function(l,index){return _c('v-list-item',{key:index,on:{"click":function () { return _vm.switchLang(l.tblCnfIdioma.codigo); }}},[_c('v-list-item-title',{staticClass:"text-uppercase"},[_vm._v(_vm._s(l.tblCnfIdioma.codigo))])],1)}),1)],1):_vm._e(),(_vm.isAuthenticated)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function () { return _vm.logout(); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-logout")])],1)]}}],null,false,2890037699)},[_c('span',[_vm._v(_vm._s(_vm.$t("AppBar.toolbar.logout")))])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function () { return _vm.$router.push(("/entities/" + (_vm.$route.params.entidadId) + "/signin")).catch(function () {}); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("AppBar.toolbar.login")))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }