<template>
  <v-app-bar :hide-on-scroll="false" app flat :color="color" height="80px" :dark="scrollPosition > 200" scroll-threshold="500">
    <v-container>
      <v-row align="center" style="position: relative;" class="px-4">
        <img
          :src="logo"
          max-height="50"
          height="50"
          :style="{
            opacity: 1 - ( ( 1 * scrollPosition )  / 200 )
          }"
          @click="() => $router.push(`/entities/${$route.params.entidadId}`).catch(() => {})"
        />

        <v-spacer></v-spacer>

        <v-tooltip bottom v-if="!$vuetify.breakpoint.mdAndDown">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-3" v-bind="attrs" v-on="on" @click="() => $router.push(`/entities/${$route.params.entidadId}/stores`).catch(() => {})">
              <v-icon>mdi-store-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("AppBar.toolbar.stores") }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-3" v-bind="attrs" v-on="on" @click="() => $router.push(`/entities/${$route.params.entidadId}/account`).catch(() => {})" v-if="isAuthenticated">
              <v-icon>mdi-account-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("AppBar.toolbar.profile") }}</span>
        </v-tooltip>

        <v-tooltip bottom v-if="itemsCount && isAuthenticated && !showCart">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-3" v-bind="attrs" v-on="on" @click.stop="$store.commit('switchCartDrawer')">
              <v-badge :content="itemsCount" bordered>
                <v-icon color="">mdi-cart</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>{{ $t("AppBar.toolbar.cart") }} ({{ itemsCount }})</span>
        </v-tooltip>

        <v-menu offset-y v-if="!$vuetify.breakpoint.mdAndDown">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-3" v-bind="attrs" v-on="on">
              <v-icon>mdi-web</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item v-for="(l, index) in langs" :key="index" @click="() => switchLang(l.tblCnfIdioma.codigo)">
              <v-list-item-title class="text-uppercase">{{ l.tblCnfIdioma.codigo }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- BOTONES DE SESION -->
        <v-tooltip bottom v-if="isAuthenticated">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="() => logout()">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("AppBar.toolbar.logout") }}</span>
        </v-tooltip>

        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="() => $router.push(`/entities/${$route.params.entidadId}/signin`).catch(() => {})">
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("AppBar.toolbar.login") }}</span>
        </v-tooltip>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex';
import { classToHex } from "vuetify/lib/util/colorUtils";
import colors from "vuetify/lib/util/colors";
import axios from 'axios';
import chroma from "chroma-js";

export default {
  computed: {
    ...mapState({
      items: (state) => state.cart.items,
      isAuthenticated: (state) => state.authentication.isAuthenticated,
    }),
    itemsCount() {
      return this.items.length;
    },
    showCart() {
      return this.$route.path == "/checkout/" + this.$route.params.entidadId;
    },
    logo() {
      const baseUrl = axios.defaults.baseURL;

      const entidadId = this.$route.params.entidadId;

      return `${baseUrl}/api/v1/entities/${entidadId}/cover`;
    }
  },
  data: () => ({
    scrollPosition: 0,
    color: 'rgb(255,255,255)',
    langs: [],
  }),
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  mounted() {
    this.fetchLanguages();
  },
  methods: {
    fetchLanguages() {
      const entidadId = this.$route.params.entidadId;

      axios.get(`api/v1/entities/${entidadId}/langs`)
        .then((res) => {
          const langs = _.get(res, 'data.data', []);

          langs.map((l) => {
            
            if (l.isDefault) {
              const defaultLang = l.tblCnfIdioma.archivo;

              this.switchLang(defaultLang);
            }
          });

          this.langs = langs;
        })
        .catch(() => {
          this.langs = [];
        });
    },
    handleScroll(e) {
      const y = window.scrollY || window.pageYOffset;

      const currentTheme = this.$vuetify.theme.currentTheme;

      const cssColor = classToHex('primary', colors, currentTheme);

      const scale = chroma.scale(['#FFFFFF', cssColor]).domain([0, 300])

      const c = scale(y);

      this.scrollPosition = y;

      this.color = c.toString();
    },
    logout() {
      this.$store
        .dispatch("authentication/logout", this.user)
        .then((res) => {

          this.$router.push('/').catch(() => {});
        })
        .catch((err) => {
          this.$console.error(err);
        });
    },
    switchLang(lang) {

      this.$i18n.locale = lang;
    },
  },
};
</script>