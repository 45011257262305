<template>
  <v-navigation-drawer v-model="$store.state.showCartDrawer" fixed temporary right width="500" class="z-999">
    <shopping-list></shopping-list>
  </v-navigation-drawer>
</template>

<script>
import ShoppingList from '../../views/shop/ShoppingList';

export default {
  components: {
    ShoppingList
  }
}
</script>