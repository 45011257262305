<template>
  <v-navigation-drawer v-model="$store.state.showMenuDrawer" app temporary width="350px">
    <v-tabs
      class="mobile-tabs-categories"
      vertical
      show-arrows
      background-color="grey lighten-2"
      color="grey--text text--darken-1">
      <v-tab v-for="(category, index) in filteredCategories" :key="'b' + index" class="d-flex flex-column">

        <v-icon>{{ category.icon || "mdi-basket" }}</v-icon>

        <div class="mb-0 text-11 text-capitalize text-truncate d-inline-block mw-70">
          {{ category.grpProducto }}
        </div>
      </v-tab>

      <v-tab-item v-for="(category, index) in filteredCategories" :key="'t' + index">
        <v-card flat>
          <v-card-text>
            <v-expansion-panels accordion flat class="mobile-expansion-panels">
              <v-expansion-panel v-for="(item, i) in category.children" :key="i">
                <v-expansion-panel-header class="px-0 font-600">
                  {{ item.grpProducto }}
                </v-expansion-panel-header>

                <v-expansion-panel-content class="mx-0 px-0">
                  <v-list dense>
                    <v-list-item v-for="(n, i) in item.children" :key="i" @click="() => gotoCategory(n.grpProductoId)">
                      <v-list-item-icon>
                        <v-icon>{{ n.icon || "mdi-basket" }}</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title v-text="n.grpProducto"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import axios from 'axios';

export default {
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.authentication.isAuthenticated,
      showMenuDrawer: (state) => state.showMenuDrawer,
    }),
    filteredCategories() {
      let categories = _.cloneDeep(this.categories);

      return this.filterTree(this.searchText, categories);
    },
  },
  mounted() {
    this.fetchCategories();
  },
  data() {
    return {
      searchText: "",
      categories: [],
    };
  },
  methods: {
    filterTree(filter, list) {
      return _.filter(list, (item) => {
        if (_.includes(_.toLower(item.grpProducto), _.toLower(filter))) {
          return true;
        } else if (item.children) {
          item.children = this.filterTree(filter, item.children);
          return !_.isEmpty(item.children);
        }
      });
    },
    fetchCategories() {
      const entidadId = this.$route.params.entidadId;

      let url = `api/v1`;

      if (this.isAuthenticated) {
        url += `/entities/${entidadId}/categories`;
      } else {
        url += `/public/entities/${entidadId}/categories`;
      }

      // SE DEFINE FUNCION PARA GENERAR ARBOL DE CATEGORIAS
      function transformToTree(arr) {
        var nodes = {};
        return arr.filter(function (obj) {
          var id = obj["grpProductoId"],
            parentId = obj["grpProductoPadreID"];

          nodes[id] = _.defaults(obj, nodes[id], { children: [] });
          parentId && (nodes[parentId] = (nodes[parentId] || { children: [] }))["children"].push(obj);

          return !parentId;
        });
      }

      this.isLoadingCategories = true;

      // SE REALIZA PETICION PARA OBTENER LAS CATEGORIAS DE PRODUCTOS DE LA TIENDA DESDE API
      axios.get(url)
        .then((res) => {

          // SE OBTIENEN LAS CATEGORIAS DE MANERA SEGURA
          const categories = _.get(res, 'data.data');

          // SE GENERA EL ARBOL DE CATEGORIAS
          const categoriesTree = transformToTree(categories);

          // SE ESTABLECE EL ARBOL DE CATEGORIAS A VARIABLE REACTIVA
          this.categories = categoriesTree;

        })
        .catch(() => {

          this.categories = [];
        })
        .finally(() => {
          this.isLoadingCategories = false;
        });
    },
    gotoCategory(grpProductoId) {
      let query = this.$route.query;
      this.$router
        .push({ path: `/categories/${grpProductoId}`, query: query })
        .catch(() => { });
    },
  },
};
</script>

<style lang="scss">
.mobile-tabs-categories {
  //height: calc(100vh - 112px);
  height: 100%;
  position: fixed;
 // top: 56px;

  .v-slide-group__content {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .v-tabs-items {
    height: 100%;
    overflow: scroll;
    background-color: transparent !important;
  }

  .v-tab {
    height: 80px !important;
    width: 90px !important;

    border-bottom: 1px solid rgb(218, 225, 231);
  }
}

.mobile-expansion-panels {
  .v-expansion-panel-content__wrap {
    padding-right: 0;
    padding-left: 0;
  }
}
</style>