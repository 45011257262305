<template>
  <v-app>
    <template v-if="!isLoading">
      <base-app-bar />

      <base-view />

      <footer-view />

      <menu-drawer v-if="$vuetify.breakpoint.mdAndDown"></menu-drawer>

      <cart-drawer></cart-drawer>
    </template>
  </v-app>
</template>

<script>
import axios from 'axios';
import  _ from 'lodash';
import BaseAppBar from './AppBar';
import BaseView from './View';
import FooterView from './Footer';

import MenuDrawer from './MenuDrawer';
import CartDrawer from './CartDrawer';

import MobileNavigation from './MobileNavigationBar';

export default {
  name: "BaseLayout",
  components: {
    BaseAppBar,
    BaseView,
    FooterView,
    MenuDrawer,
    CartDrawer,
    MobileNavigation,
  },
  watch: {
    '$route.params.entidadId': {
      immediate: true,
      handler() {
        this.fetchColorTheme();
      },
    },
  },
  data(){
    return {
      isLoading: true,
    }
  },
  methods: {
    fetchColorTheme(){
      const entidadId = this.$route.query.organization || this.$route.params.entidadId;

      this.isLoading = true;

      axios.get(`api/v1/entities/${entidadId}/theme`)
        .then((res) => {
          const payload = _.get(res, 'data.data', {});
          
          const defaultTheme = this.$vuetify.theme.defaults.light;

          const colorTheme = {
            primary: payload.primaryColor || defaultTheme.primary,
            secondary: payload.secondaryColor || defaultTheme.secondaryr,
            accent: payload.accentColor || defaultTheme.accent
          }

          _.merge(this.$vuetify.theme.themes.light, colorTheme);

        })
        .catch(() => {
          // DOES NOTHING
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
