<template>
  <v-footer color="transparent" class="py-15">
    <v-container>
      <v-row>
        <v-col cols="12" xl="3" lg="3" md="3" sm="12">
          <h2 class="mb-5 text-uppercase primary--text">{{ $t("Footer.aboutUs.aboutUs") }}</h2>

          <v-list-item dense v-for="(n, index) in aboutUs" :key="index" :href="n.url">
            <v-list-item-icon><v-icon>{{ n.icon }}</v-icon></v-list-item-icon>
            <v-list-item-title>{{ n.label }}</v-list-item-title>
          </v-list-item>
        </v-col>

        <v-col cols="12" xl="3" lg="3" md="3" sm="12">
          <h2 class="mb-5 text-uppercase primary--text">{{ $t("Footer.content.content") }}</h2>

          <v-list-item dense v-for="(n, index) in content" :key="index" :to="n.url">
            <v-list-item-icon><v-icon>{{ n.icon }}</v-icon></v-list-item-icon>
            <v-list-item-title>{{ n.label }}</v-list-item-title>
          </v-list-item>
        </v-col>

        <v-col cols="12" xl="6" lg="6" md="3" sm="12">

          <v-img max-height="50px" contain :src="logo"></v-img>

          <v-list>
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>{{ $t('Footer.contact.address') }}</v-list-item-title>
                <v-list-item-subtitle>{{ info.domicilio }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon><v-icon>mdi-map-marker-outline</v-icon></v-list-item-icon>
            </v-list-item>

            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>{{ $t('Footer.contact.email') }}</v-list-item-title>
                <v-list-item-subtitle>{{ info.email }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon><v-icon>mdi-email-outline</v-icon></v-list-item-icon>
            </v-list-item>

            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>{{ $t('Footer.contact.phone') }}</v-list-item-title>
                <v-list-item-subtitle>{{ info.telefono }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon><v-icon>mdi-phone-outline</v-icon></v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
/**
 * MODIFICACIONES:
 * 1. SE REMUEVE OBTENCION DE PARAMETROS DESDE VARIABLES DE ENTORNO
 * 2. SE AGREGA PETICION PARA OBTENER INFORMACION DE TIENDA
 * 3. SE CORRIGE ESTILO DEL FOOTER QUE OCASIONABA ERRORES DE RENDERIZADO EN BUILD
 */
import axios from 'axios';
import _ from 'lodash';

export default {
  computed: {
    aboutUs() {
      return [
        {
          label: this.$t("Footer.aboutUs.company"),
          url: "https://wellcom.biz/#/empresa",
          icon: 'mdi-domain'
        },
        {
          label: this.$t("Footer.aboutUs.solutions"),
          url: "https://wellcom.biz/#/hub",
          icon: 'mdi-tools'
        },
        {
          label: this.$t("Footer.aboutUs.partners"),
          url: "https://wellcom.biz/#/asociados",
          icon: 'mdi-handshake-outline'
        },
        {
          label: this.$t("Footer.aboutUs.support"),
          url: "https://wellcom.biz/#/soporte",
          icon: 'mdi-face-agent'
        },
        {
          label: this.$t("Footer.aboutUs.contactUs"),
          url: "https://wellcom.biz/#/contacto",
          icon: 'mdi-card-account-phone'
        },
      ];
    },
    content() {
      return [
        {
          label: this.$t("Footer.content.home"),
          url: "/",
          icon: 'mdi-home'
        },
        {
          label: this.$t("Footer.content.profile"),
          url: "/account",
          icon: 'mdi-account'
        },
        {
          label: this.$t("Footer.content.orders"),
          url: "/account/orders",
          icon: 'mdi-shopping-outline'
        },
        {
          label: this.$t("Footer.content.addresses"),
          url: "/account/addresses",
          icon: 'mdi-map-marker-outline'
        },
        {
          label: this.$t("Footer.content.contacts"),
          url: "/account/contacts",
          icon: 'mdi-card-account-phone-outline'
        },
        {
          label: this.$t("Footer.content.wishlist"),
          url: "/account/wishlist",
          icon: 'mdi-heart-outline'
        },
      ];
    },
    logo() {
      const baseUrl = axios.defaults.baseURL;

      const entidadId = this.$route.params.entidadId;

      return `${baseUrl}/api/v1/entities/${entidadId}/cover`;
    }
  },
  data() {
    return {
      info: {}
    }
  },
  mounted() {
    this.fetchInfo();
  },
  methods: {
    fetchInfo() {
      const entidadId = this.$route.params.entidadId;

      axios.get(`api/v1/entities/${entidadId}/info`)
        .then((res) => {
          const payload = _.get(res, 'data.data', {});

          this.info = payload;
        })
        .catch(() => {
          this.info = {};
        });
    }
  },
};
</script>